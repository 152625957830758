
import bus from "../common/bus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import { Deep } from '../com/util/deepCopy';
@Component
export default class HelloWorld extends Vue {
  private tagsList: any[] = [];

  private isActive(route: any) {
    return route.name === this.$route.name;
  }

  // 关闭单个标签
  private closeTags(index: number) {
    console.log(this.tagsList.length);
    if (this.tagsList.length == 1) {
      this.$router.push(this.tagsList[0].fullPath);
      this.tagsList.splice(0, 1);
      return;
    }
    const delItem = this.tagsList.splice(index, 1)[0];
    const item = this.tagsList[index]
      ? this.tagsList[index]
      : this.tagsList[index - 1];
    if (item) {
      delItem.name === this.$route.name && this.$router.push(item.fullPath);
    } else {
      const item: any = JSON.parse(localStorage.getItem("menus")!);
      for (const i in item) {
        if (item[i].children.length) {
          this.$router.push(item[i].children[0].url);
          break;
        }
      }
      // this.$router.push('/locationManage');
    }
  }

  // 关闭全部标签
  private closeAll() {
    this.tagsList = [];
    this.$router.push("/");
  }

  // 关闭其他标签
  private closeOther() {
    const curItem = this.tagsList.filter((route) => {
      return route.name == this.$route.name;
    });
    this.tagsList = curItem;
  }

  // 设置标签
  private setTags(route: any) {
    // console.log(route, 'route')
    // console.log(this.tagsList, 'this.tagsList')
    // console.log(this.tagsList.findIndex((tagItem: any) => tagItem.name == route.name), 'this.tagsList.findIndex((tagItem: any) => tagItem == route)')
    const existRouteIndex = this.tagsList.findIndex((tagItem: any) => tagItem.name == route.name)
    const isExist = existRouteIndex > -1
    if (!isExist) {
      if (this.tagsList.length >= 8) {
        this.tagsList.shift();
      }
      // const menus = JSON.parse(localStorage.getItem("menus")!);
      // let title = route.meta.title;
      // outside: for (const i in menus) {
      //   for (const j in menus[i].children) {
      //     if (route.fullPath === menus[i].children[j].url) {
      //       title = menus[i].children[j].name;
      //       break outside;
      //     }
      //   }
      // }
      this.tagsList.push(route);
    } else {
      this.tagsList.splice(existRouteIndex, 1, route)
    }
    bus.$emit("tags", this.tagsList);
  }

  private handleTags(command: string) {
    command === "other" ? this.closeOther() : this.closeAll();
  }

  get showTags(): boolean {
    return this.tagsList.length > 0;
  }

  @Watch("$route")
  $routechange(newValue: string, oldValue: string) {
    this.setTags(this.$route);
  }

  private flatJson: any = {};

  private deepFlatJson(data: any): void {
    // 递归平铺树状数据

    data.forEach((value: any, index: any, arr: any) => {
      if (value.url) {
        this.flatJson[value.url] = value.name;
      }

      if (value.children && value.children.length !== 0) {
        this.deepFlatJson(value.children);
      }
    });
  }

  private created() {
    //     const deep: Deep = new Deep(this.flatJson);
    //     deep.deepFlatJson(JSON.parse(localStorage.getItem('menus')!));
    this.deepFlatJson(JSON.parse(localStorage.getItem("menus")!));

    this.setTags(this.$route);
    // 监听关闭当前页面的标签页
    bus.$on("close_current_tags", () => {
      for (let i = 0, len = this.tagsList.length; i < len; i++) {
        const item = this.tagsList[i];
        if (item.name === this.$route.name) {
          if (i < len - 1) {
            this.$router.push(this.tagsList[i + 1].fullPath);
          } else if (i > 0) {
            this.$router.push(this.tagsList[i - 1].fullPath);
          } else {
            this.$router.push("/");
          }
          this.tagsList.splice(i, 1);
          break;
        }
      }
    });
  }
}
